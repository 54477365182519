import { create } from 'zustand'
import moment from 'moment'

export default create((set, get) => ({
  user: {},
  termsTrip: false,
  targetUrl: '',
  userError: false,
  loadingUser: false,
  loadedUser: false,
  loadingTravelers: false,
  loadedTravelers: false,
  popup: false,
  upcomingTravelers: [],
  pastTravelers: [],
  currentTravelers: [],
  genTravelers: [],
  canceledTravelers: [],
  postTermsURL: '',
  setPostTermsURL(postTermsURL) {set({postTermsURL})},
  flash: [],
  anyTravelers: false,
  setPopup(popup) {
    set({popup: popup})
  },
  async fetchUser(force = false) {
    if (get().loadedUser && !force) return
    set({loadingUser: true, loadedUser: true})
    try {
      const res = await fetch('/profile.json')
      const json = await res.json()
      set({
        user: json.user,
        flash: json.flash,
      })
    } catch (error) {
      set({userError: true, loadedUser: false})
    }
    set({loadingUser: false})
  },
  async fetchTravelers(force = false) {
    if (get().loadedTravelers && !force) return
    set({loadingTravelers: true, loadedTravelers: true})
    try {
      const res = await fetch('/travelers.json')
      const json = await res.json()
      set({
        pastTravelers: json.past_travelers,
        upcomingTravelers: json.upcoming_travelers,
        currentTravelers: json.current_travelers,
        genTravelers: json.gen_travelers,
        canceledTravelers: json.canceled_travelers,
      })
    } catch (error) {
      set({userError: true, loadedTravelers: false})
    }
    set({loadingTravelers: false})
  },
  setTermsTrip(trip) {
    set({termsTrip: trip})
  },
  acceptedTerms() {
    const trip = get().termsTrip
    let travelers = get().upcomingTravelers
    let index = travelers.findIndex((t) => t.id === trip.id)
    if (index === -1) {
      travelers = get().currentTravelers
      index = travelers.findIndex((t) => t.id === trip.trip_id)
      trip.needs_terms = false
      travelers.splice(index, 1, trip)
      return set({currentTravelers: travelers})
    }
    trip.needs_terms = false
    travelers.splice(index, 1, trip)
    set({upcomingTravelers: travelers})
  },
  dismissAccountSwitcher: async () => {
    await fetch('/dismiss-account-switcher')
    get().fetchUser(true)
  },
  setFlash(message, type = 'notice') {
    set({flash: [ ...get().flash, {message, type} ]})
    setTimeout(() => {
      get().clearFlash()
    }, 3000)
  },
  clearFlash() {
    const flash = get().flash
    flash.shift()
    set({flash})
  }
}))
