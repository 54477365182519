import { twMerge } from 'tailwind-merge'
import { clsx } from 'clsx'
import numeral from 'numeral'
import moment from 'moment'

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export function htmlToText(html) {
  var temp = document.createElement('div');
  temp.innerHTML = html;
  return temp.textContent; 
}

export function currency(value) {
  return numeral(value).format('$0,0.00')
}

export function date(value) {
  return moment(value, 'MM/DD/YYYY').format('dddd, MMMM D, YYYY')
}
