import React, {useEffect, useState, useRef, useContext} from 'react'
import Cleave from "cleave.js/react"
import Select2 from 'react-select'
import AsyncSelect from 'react-select/async'
import SignatureCanvas from 'react-signature-canvas'
import Button from '../components/Button'
import { Label } from 'react-bootstrap'
import { cn } from '../lib/utils'

export const inputClasses = `
  box-border
  bg-white
  text-xs 
  block
  w-full
  h-8
  px-2
  py-1
  leading-0
  border
  rounded
  transition-all
  duration-250
  focus:border-sky-500
  focus:shadow-field
  focus:shadow-sky-300
  outline-none
  group-[.has-error]:border-red-700
  group-[.has-error]:shadow-red-400
  read-only:bg-gray-100
`

export const FormContext = React.createContext({})

export function FormGroup({children, id, className, errors = []}) {
  return(
    <div className={cn(`mb-4 ${errors.length > 0 ? 'group has-error text-red-700': ''}`, className)} id={id}>
      {children}
    </div>
  )
}
export function ControlLabel({children, className}) {
  return(
    <label className={cn("group-[.has-error]:text-red-700 inline-block font-bold mb-1 max-w-full", className)}>
      {children}
    </label>
  )
}
export function HelpBlock({children, className}) {
  return(
    <p className={cn("mb-0 mt-1 text-sm text-gray-500 group-[.has-error]:text-red-700", className)}>{children}</p>
  )
}

function Field({name, label, hint, children, labelClasses, helpClasses, formGroupClasses, ...props}) {
  const errors = useContext(FormContext)?.[name] ?? props.errors ?? []
  return(
    <FormGroup errors={errors} className={formGroupClasses} id={'field-' + name}>
      {label &&
        <ControlLabel className={labelClasses}>{label}</ControlLabel>
      }
      {children}
      {hint && 
        <HelpBlock>{hint}</HelpBlock>
      }
      {errors && errors.length > 0 && errors.map((error) =>
        <HelpBlock key={error} className={helpClasses}>{error}</HelpBlock>
      )}
    </FormGroup>
  )
}

export function Text(props) {
  const {
    name,
    type = 'text',
    minLength,
    maxLength,
    readOnly,
    className,
    placeholder,
    disabled = false,
    defaultValue,
    onChange = () => {},
    value,
  } = props

  return(
    <Field {...props}>
      <input
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={cn(`${inputClasses} ${readOnly && "bg-gray-100"}`, className)}
        minLength={minLength}
        maxLength={maxLength}
        readOnly={readOnly}
        placeholder={placeholder}
        name={name}
        disabled={disabled}
        defaultValue={defaultValue}
        />
    </Field>
  )
}

export function CreditCard(props) {
  const {name, type = 'text', onChange, onTypeChange, value = ''} = props
  return(
    <Field {...props}>
      <Cleave
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={inputClasses}
        name={name}
        options={{
          creditCard: true,
          onCreditCardTypeChanged: onTypeChange,
        }}
        />
    </Field>
  )
}

export function ExpDate(props) {
  const {name, type = 'text', onChange, value = ''} = props
  return(
    <Field {...props}>
      <Cleave
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={inputClasses}
        placeholder="MM/YY"
        name={name}
        options={{
          date: true,
          datePattern: ['m', 'y'],
        }}
        />
    </Field>
  )
}

export function Select(props) {
  const {options = [], value, type = 'text', name, onChange = () => {}, valueKey = 'value', textKey = 'text'} = props
  const blankOption = options.find((option) => option[valueKey] == '')
  return(
    <Field {...props}>
      <select
        type={type}
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={inputClasses}
      >
        {!blankOption && 
          <option value="">- select -</option>
        }
        {options && options.map((option, index) =>{
          if (typeof option == 'object') {
            return <option value={option[valueKey]} key={option[valueKey]}>{option[textKey]}</option>
          } else {
            return <option value={option} key={index}>{option}</option>
          }
        }
        )}
      </select>
    </Field>
  )
}

export function Boolean(props) {
  const { name, disabled = false, onChange, label, hint, value = '0', type = 'text', yesLabel, errors = [], optionLabelClasses, readOnly } = props

  return(
    <>
      <Field {...props}>
        <label className={cn('text-sm flex items-top mb-4 group-[.has-error]:text-red-700 leading-0', optionLabelClasses)}>
          <input
            type='checkbox'
            checked={value == '1'}
            onChange={(e) => {
              onChange(e.target.checked ? '1' : '0')
            }}
            className={cn("w-3 h-3 mt-1 mr-2 shrink-0")}
            name={name}
            readOnly={readOnly}
            disabled={disabled}
          />
            {yesLabel ?? 'Yes'}
        </label>
      </Field>
    </>
  )
}

export function TextArea(props) {
  const {
    name,
    value,
    defaultValue,
    type = 'text',
    textAreaClasses,
    placeholder,
    rows = 2,
    readOnly = false,
    disabled = false,
    onChange = () => {},
  } = props
  return(
    <>
      <Field {...props}>
        <textarea
          name={name}
          type={type}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className={cn(inputClasses, "h-auto", textAreaClasses)}
          rows={rows}
          disabled={disabled}
          placeholder={placeholder}
          readOnly={readOnly}
          defaultValue={defaultValue}
        >
        </textarea>
      </Field>
    </>
  )
}

export function SearchableSelect(props) {
  const { value = '', errors = [], options = [], name, onChange, className, helpClasses, formGroupClasses} = props
  const styles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      lineHeight: '1em',
      height: '34px',
      minHeight: '34px',
      fontSize: '0.75rem',
    })
  }

  return(
    <>
      <Field {...props}>
        {options.length > 1 &&
          <Select2
            options={options}
            onChange={(e) => {
              onChange(e.value)
            }}
            value={options.find((o) => {
              return o.value.toString().trim() == value?.toString()?.trim()
            })}
            styles={styles}
            menuPosition="fixed"
          />
        }
      </Field>
    </>
  )
}

export function AsyncSearchableSelect(props) {
  const { value = '', errors = [], options = [], loadOptions, name, onChange, className, helpClasses, formGroupClasses} = props
  const styles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      lineHeight: '1em',
      height: '34px',
      minHeight: '34px',
      fontSize: '0.75rem',
    })
  }

  return(
    <>
      <Field {...props}>
        <AsyncSelect
          onChange={(e) => {
            onChange(e.value)
          }}
          value={options.find((o) => {
            return o.value.trim().toString() == value.trim().toString()
          })}
          styles={styles}
          loadOptions={loadOptions}
          cacheOptions
          defaultOptions
        />
      </Field>
    </>
  )
}

export function Radios (props) {
  const {name, value, onChange = () => {}, hint, label, errors = [], options, radioClasses, labelClasses, className, readOnly = false, disabled = false} = props

  return(
    <>
      <Field {...props}>
        <div className={className}>
          {options && options.map((option) =>
            
            <label key={option.value} className='flex items-top gap-2 mb-3 text-sm'>
              <input
                type='radio'
                name={name}
                value={option.value}
                onChange={e => onChange(e.target.value)}
                checked={value ? option.value == value : null}
                className={cn(`${inputClasses} w-3 h-3 mt-1`, radioClasses)}
                readOnly={readOnly}
                disabled={disabled}
              />
                {option.text}&nbsp;
            </label>
          )}
        </div>
      </Field>
    </>
  )
}

export function Checkboxes(props) {
  const {
    label = '',
    value = '',
    onChange,
    hint,
    errors = [],
    options = [],
    labelClasses,
    className,
    disabled = false
  } = props
  const [myOptions, setMyOptions] = useState([])

  // updates state of all options when an option gets (un)checked
  function updateOption(e) {
    const newOptions = [...myOptions]
    const index = newOptions.findIndex((o) => o.value == e.target.value)
    newOptions[index].checked = e.target.checked
    setMyOptions(newOptions)
  }

  // when myOptions changes, replace obj[name] with a string created from checked options
  useEffect(() => {
    onChange(myOptions.filter((o) => o.checked).map((o) => o.value).join("\n"))
  }, [myOptions])

  useEffect(() => {
    const newOptions = options.map((o) => {
      return {
        value: o.value,
        text: o.text,
        checked: value.split("\n").includes(o.value)
      }
    })
    setMyOptions(newOptions)
  }, [])

  return(
    <>
      <Field {...props}>
        <div>
          {myOptions && myOptions.map((option) =>
          <label key={option.value} className='flex items-top gap-2 mb-3 text-sm group-[.has-error]:text-red-700'>
            <input
              type='checkbox'
              value={option.value}
              onChange={e => updateOption(e)}
              checked={option.checked}
              className={cn("w-3 h-3 mt-1", className)}
              disabled={disabled}
            />
            {option.text}&nbsp;
          </label>
          )}
        </div>
      </Field>
    </>
  )
}

export function Signature(props) {
  const {name, value = '', onChange, object, errors = []} = props
  const ref = useRef(null)
  function clear() {
    ref.current.clear()
    onChange(false)
  }
  return(
    <Field {...props}>
      <div className="relative">
        <SignatureCanvas
          canvasProps={{className: `${inputClasses.replace('h-8', '')} w-full h-48`}}
          ref={ref}
          onEnd={() => onChange(ref.current.toDataURL())}
          name={name}
        />
        <Button onClick={clear} className="absolute bottom-0 left-0 rounded rounded-tl-none px-2 py-1 rounded-br-none">Clear</Button>
      </div>
    </Field>
  )
}
