import React, { useEffect, useState } from 'react'
import Table from "../components/Table"
import Accordion from "../components/Accordion"
const contentful = require('contentful')

const client = contentful.createClient({
  space: 'omy90ch5k9mb',
  accessToken: '8QC8yOLoLoM9HJZYyMHxDisW_aVvXM6WvW5KO9JIJdY',
  environment: 'master'
})

const richTextRenderer = {
  renderNode: {
    'paragraph': (node, children) => <p className="last:mb-0">{children}</p>,
    'embedded-asset-block': (node) => <img src={node.data.target.fields.file.url} alt={node.data.target.fields.title} />,
    'ordered-list': (node, children) => <ol className="list-decimal pl-6 mb-2">{children}</ol>,
    'unordered-list': (node, children) => <ul className="list-disc pl-6 mb-2">{children}</ul>,
    'list-item': (node, children) => <li className="mb-2">{children}</li>,
    'table': (node, children) => <Table className="mb-2"><Table.Head>{children.shift()}</Table.Head><Table.Body>{children}</Table.Body></Table>,
    'table-row': (node, children) => <Table.Row>{children}</Table.Row>,
    'table-header-cell': (node, children) => <Table.Th>{children}</Table.Th>,
    'table-cell': (node, children) => <Table.Td className="min-w-48">{children}</Table.Td>,
    'embedded-entry-block': (node, children) => <EmbeddedEntryBlock {...{ node, children }}>{children}</EmbeddedEntryBlock>,
    'embedded-entry-inline': (node, children) => <EmbeddedEntryInline {...{ node, children }}>{children}</EmbeddedEntryInline>,
    'hyperlink': (node, children) => <a href={node.data.uri} target="_blank" className="link">{children}</a>,
  }
}

  // accordion label = entry.fields.items.map(item => item.fields.title)
  // accordion content = entry.fields.items.map(item => item.fields.content.content[0].value)


const EmbeddedEntryBlock = ({ node, children }) => {
  const [entry, setEntry] = useState({})
  const [content, setContent] = useState([])
  const [elementType, setElementType] = useState(null)
  useEffect(() => {
    client.getEntry(node.data.target.sys.id).then(setEntry)
  }, [])

  // useEffect(() => console.log(entry.fields), [entry])

  useEffect(() => {
    setElementType(entry.sys?.contentType.sys.id)
  }, [entry])

  return (
    <div>
      {elementType == "accordion" && <Accordion {...{ entry }} scroll={true} />}
    </div>
  )
}

const EmbeddedEntryInline = ({ node, children }) => {
  const [data, setData] = useState({})
  useEffect(() => {
    client.getEntry(node.data.target.sys.id).then(setData)
  }, [])

  useEffect(() => console.log(node), [data])

  return (
    <div>
      {JSON.stringify(data)}
    </div>
  )
}

export { richTextRenderer }
export default client
