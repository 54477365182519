import React, { useState, useRef, useEffect } from 'react'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { richTextRenderer } from "../lib/contentful"
import { cn } from "../lib/utils"
import { Element, scroller } from 'react-scroll'

const AccordionItem = ({ item, openItem, setOpenItem }) => {
  const contentRef = useRef(null)
  const isExpanded = openItem === item.sys.id
  function toggle() {
    if (isExpanded) return setOpenItem(null)
    setOpenItem(item.sys.id)
  }
  return (
    <div className="overflow-hidden border rounded-lg mb-4">
      <div className="px-4 py-2 bg-gray-100 cursor-pointer flex justify-between items-center w-full" onClick={toggle}>
        <div className="flex items-center" name={item.fields.title}>
          {item.fields.title}
        </div>
        <div>
          {isExpanded ? 
            <i className="fa-solid fa-minus fa-lg text-link"></i>
            :
            <i className="fa-solid fa-plus fa-lg text-link"></i>
          }
        </div>
      </div>
      <section
        ref={contentRef}
        className="text-sm relative z-0 transition-all"
        style={{ maxHeight: isExpanded ? contentRef.current.scrollHeight : 0 }}
      >
        <div className="px-4 py-2 border-t">
          {documentToReactComponents(item.fields.content, richTextRenderer)}
        </div>
      </section>
    </div>
  )
}

const Accordion = ({ entry, scroll = false }) => {
  const [openItem, setOpenItem] = useState(null)
  const ref = useRef(null)
  useEffect(() => {
    if (!scroll) return
    if (!openItem) return
    setTimeout(() => {
      scroller.scrollTo(openItem, {
        duration: 500,
        smooth: 'easeInOutQuint',
        offset: -36,
        containerId: 'planInfoModalBody'
      })
    }, 300)
  }, [openItem])
  return (
    <div className="my-8">
      {entry.fields.items.map((item, index) =>
        <Element ref={ref} name={item.sys.id} key={item.sys.id} id={item.sys.id}>
          <AccordionItem name={item.sys.id} {...{ item, openItem, setOpenItem }} />
        </Element>
      )}
    </div>
  )
}

export default Accordion
