import React from 'react';
import { Link } from 'react-router-dom'
import { cn } from '../lib/utils'

export const baseClasses = `bg-button-size enabled:hover:bg-right bg-button-gradient group`

export const classes = `
  px-4
  py-2
  rounded-lg
  text-white
  transition-all
  duration-250
  ease-in-out
  inline-flex
  items-center
  justify-center
  gap-4
  font-condensed
  font-bold
  cursor-pointer
  disabled:cursor-not-allowed
  disabled:opacity-50
  disabled:bg-gray-400
`

const Button = ({to, children, href, block, loading = false, disabled, className = "", ...props}) => {
  if (to) {
    return (
      <Link to={to} className={baseClasses + ' ' + cn(classes, className)} {...props}>
        {children}
      </Link>
    )
  }
  if (href) {
    return (
      <a href={href} className={baseClasses + ' ' + cn(classes, className)} {...props}>
        {children}
      </a>
    )
  }
  return (
    <button className={baseClasses + ' ' + cn(classes, className)} {...props} disabled={loading || disabled}>
      {loading && <i className="fas fa-spinner fa-spin"></i>}
      {children}
    </button>
  )
}

export default Button;
