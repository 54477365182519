import React from 'react'

export default function () {
  const classes = "transition-all ease-in-out duration-250 border-transparent border-b hover:border-white hover:text-white"
  return (
    <footer className="print:hidden bg-sky-800 text-white py-16 px-2 md:px-4 text-sm">
      <div className="">
        <div className="mx-auto flex gap-x-2 md:gap-x-4 flex-wrap justify-center mb-2">
          <span>Copyright &copy; {new Date().getFullYear()}</span>
          <span className="sep"><i className="fa fa-period relative -top-1" /></span>
          <span>Natural Habitat Adventures</span>
          <span className="sep"><i className="fa fa-period relative -top-1" /></span>
          <span>U.S. &amp; Canada 800-543-8917</span>
          <span className="sep"><i className="fa fa-period relative -top-1" /></span>
          <span>International 303-449-3711</span>
          <span className="sep"><i className="fa fa-period relative -top-1" /></span>
          <a href="https://www.nathab.com" target="_blank" className={classes}>www.nathab.com</a>
        </div>
        <div className="mx-auto flex gap-4 flex-wrap justify-center">
          <a href="https://www.nathab.com/terms-of-service/" target="_blank" className={classes}>Terms of Service</a>
          <span className="sep"><i className="fa fa-period relative -top-1" /></span>
          <a href="/terms-and-conditions" target="_blank" className={classes}>Photo Sharing Terms</a>
          <span className="sep"><i className="fa fa-period relative -top-1" /></span>
          <a href="https://www.nathab.com/privacy-policy/" target="_blank" className={classes}>Privacy Policy</a>
        </div>
      </div>
    </footer>
  )
}
