import React from 'react';
import ReactDOM from 'react-dom/client';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../style.scss';

const container = document.getElementById('header')
const root = ReactDOM.createRoot(container)
root.render(<Header />)

const footerContainer = document.getElementById('footer')
const footerRoot = ReactDOM.createRoot(footerContainer)
footerRoot.render(<Footer />)
