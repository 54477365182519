import React from 'react';
import { cn } from '../lib/utils';

const Table = ({ children, className }) => {
  return (
    <div className="w-full overflow-x-auto">
      <table className={cn(
        "table-auto w-full text-gray-800 border-collapse", 
        className
      )}>
        {children}
      </table>
    </div>
  );
}

Table.Head = ({ children, className }) => {
  return (
    <thead className={cn(
      "border-b-4 border-gray-200 text-left", 
      className
    )}>
      {children}
    </thead>
  );
}

Table.Body = ({ children, className }) => {
  return (<tbody className={cn("", className)}>{children}</tbody>);
}

Table.Foot = ({ children, className }) => {
  return (
    <tfoot className={cn(
      "border border-t-2 border-gray-200 text-left", 
      className
    )}>
      {children}
    </tfoot>
  );
}

Table.Row = ({ children, className, ...props }) => {
  return (<tr className={cn("odd:bg-gray-50", className)} {...props} >{children}</tr>);
}

Table.Td = ({ children, className, ...props }) => {
  return (
    <td className={cn("border-b-2 px-2 py-1 first:pl-0 last:pr-0", className)} {...props}>
      {children}
    </td>
  )
}

Table.Th = ({ children, className, ...props }) => {
  return (
    <th className={cn("px-2 py-1 first:pl-0 last:pr-0", className)} {...props}>
      {children}
    </th>
  )
}

export default Table;
