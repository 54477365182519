import { create } from 'zustand'

export default create((set, get) => ({
  tripTraveler: {},
  tripTravelerError: false,
  loadingTripTraveler: true,
  loadedTripTraveler: false,
  clearError: () => set({tripTravelerError: false}),
  setTripTraveler(tripTraveler) {
    set({tripTraveler})
  },
  fetchTripTraveler: async (id) => {
    set({loadingTripTraveler: true, loadedTripTraveler: true})
    try {
      const res = await fetch(`/api/travelers/${id}.json`)
      if (!res.ok) throw new Error('Failed to fetch traveler')
      const json = await res.json()
      set({
        tripTraveler: json,
      })
    } catch (error) {
      set({tripTravelerError: true, loadedTripTraveler: false})
    }
    set({loadingTripTraveler: false})
  },
  viewedDocs: () => {
    const traveler = {...get().tripTraveler}
    fetch(`/api/travelers/${traveler.record_id}/documents.json`)
      .then(() => {
        traveler.new_docs_count = 0
        set({tripTraveler: traveler})
      })
  }
}))
