import React, {useState, useEffect} from 'react'
import { Link, useParams, redirect, useLoaderData, useNavigate, useSearchParams } from 'react-router-dom'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { cn } from '../lib/utils'
import { Select } from '../components/Fields'
import { scroller } from 'react-scroll'
import client, { richTextRenderer } from '../lib/contentful'
import useStore from '../store'

export default function Article() {

  const [testData, setTestData] = useState([])

  // const [item, setItem] = useState({})
  const item = useLoaderData()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const [
    user,
  ] = useStore((state) => [
    state.user,
  ])

  const [category, setCategory] = useState({})

  const { categoryId, id } = useParams()

  useEffect(() => {
    if (!user) return
    if (!user.id) return
    if (user.isGuide) return
    if (user.is_admin) return
    navigate('/')
  }, [user])

  useEffect(() => {
    client.getEntries({content_type: 'fieldTeamPortalCategory', 'fields.slug': categoryId})
      .then((response) => {
        setCategory(response.items[0])
      })
      .catch(console.error)
  }, [categoryId])

  useEffect(() => {
    const section = searchParams.get('section')
    if (!section) return
    setTimeout(() => {
      scroller.scrollTo(section, {duration: 500, smooth: true, offset: -100})
    }, 100)
  }, [searchParams])
    

  function scroll(e) {
    if (!e.target.value) return
    // scroller.scrollTo(e.target.value, {duration: 500, smooth: true, offset: -100})
    setSearchParams({section: e.target.value})
  }

  return(
    <>
      <div className="flex items-stretch" style={{minHeight: 'calc(100vh - 13.75rem)'}}>
        <div className="hidden md:block basis-72 shrink-0 bg-gray-200 px-2 py-8">
          <div className="px-4 py-2 text-lg font-bold">{category.fields?.title}</div>
          {category.fields?.children?.map((c) =>
            <Category key={c.sys.id} topLevelCategory={category} category={c} />
          )}
        </div>
        <div className="px-6 py-8 grow">
          <div className="mx-auto max-w-2xl">
            <h1 className={cn("border-b-2 border-gray-200 pb-4", item.fields?.children?.length > 0 ? 'mb-0' : 'mb-4')}>{item.fields?.title}</h1>
            {item.fields?.children?.length > 0 &&
              <div className="grid lg:grid-cols-2 gap-4 md:gap-8 items-center mb-6 py-4 border-b-2 border-gray-200 sticky top-0 bg-white">
                <p className="mb-0 text-sm">Scroll to read all content, or select a topic to jump directly to that specific section.</p>
                <div className="basis-64">
                  <select className="px-2 py-1 border rounded font-bold w-full" onChange={scroll}>
                    <option value="">Jump to a topic</option>
                    {item.fields?.children?.map((section) =>
                      <option key={section.sys.id} value={section.fields?.slug}>{section.fields?.title}</option>
                    )}
                  </select>
                </div>
              </div>
            }
            <div>{documentToReactComponents(item.fields?.content, richTextRenderer)}</div>
            {item.fields?.children?.map((section) =>
              <div key={section.sys.id} id={section.fields?.slug}>
                {user.is_admin &&
                  <div className="pull-right cursor-pointer" onClick={() => navigator.clipboard.writeText(`${window.location.origin}${window.location.pathname}?section=${section.fields.slug}`)}>
                    <i className="fa-solid fa-link"></i>
                  </div>
                }
                <h2>{section.fields?.title}</h2>
                <div>{documentToReactComponents(section.fields?.content, richTextRenderer)}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export function Category({category, topLevelCategory}) {

  const [items, setItems] = useState([])

  const { categoryId, id } = useParams()

  const [open, setOpen] = useState(false)

  useEffect(() => {
    client.getEntry(category.sys.id)
      .then((entry) => {
        setItems(entry.fields.children)
      })
      .catch(console.error)
  }, [])

  useEffect(() => {
    setOpen(items?.find((i) => i.fields?.slug == id))
  }, [items])

  return(
    <div className={cn(`px-4 py-2 bg-gray-100 text-xl md:text-base font-medium rounded mb-2`)}>
      <a href="#" onClick={() => setOpen(!open)} className="flex justify-between items-center">
        <span>{category.fields?.title}</span>
        <i className={cn(`fas fa-chevron-${open ? 'up' : 'down'}`)}></i>
      </a>
      <div className={cn(`max-h-0 overflow-hidden border-box transition-all`, open && 'pt-2 max-h-max')}>
        {items?.map((item) =>
          <Link
            className={cn('block px-4 py-2 leading-none border-l-[0.25rem] border-transparent', id == item.fields?.slug && 'border-l-link bg-gray-50 rounded')}
            to={`/field-team/category/${topLevelCategory.fields?.slug}/article/${item.fields?.slug}`}
            key={item.sys.id}
          >
            {item.fields?.title}
          </Link>
        )}
      </div>
    </div>
  )
}

export async function articleLoader({params: {categoryId, id}}) {
  let response = null
  response = await client.getEntries({content_type: 'fieldTeamPortalCategory', 'fields.slug': id})
  return response.items[0]
}
